import "./ChangeLogs.scss";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    getChangeLogs,
    addChangeLogs,
    removeChangeLogs,
    editChangeLogs,
    retryFn,
} from "utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef, createColumnHelper, RowData, RowModel } from "@tanstack/react-table";
import AbunTable, { IndeterminateCheckbox } from "components/AbunTable/AbunTable";
import Modal from "components/Modal/Modal";
import Alert from "components/Alert/Alert";
import TextArea from "../../components/TextArea/TextArea";
import { DateTime } from "luxon";


interface ChangeLog {
    id: string
    title: string,
    description: string,
    created_at: string,
}

let err_message = "";

// A simple page that shows a list of block website keywords, allows adding new ones one at a time and deleting existing ones in bulk.
export default function ChangeLogs() {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 50, 100];

    // -------------------------- STATES --------------------------
    const [changeLogs, setChangeLogs] = useState<Array<ChangeLog>>([]);
    const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
    const [addChangeLogToModal, setAddChangeLogToModal] = useState(false);
    const [changeLogTitle, setChangeLogTitle] = useState("")
    const [changeLogDescription, setChangeLogDescription] = useState("")
    const [changeLogDate, setChangeLogDate] = useState("")
    const [changeLogID, setChangeLogID] = useState("")
    const [RemoveActionConfirmationModal, setRemoveActionConfirmationModal] = useState(false);
    const [editChangeLogModal, setEditChangeLogModal] = useState(false);

    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data,
        refetch,
    } = useQuery({
        queryKey: ['getChangeLogs'],
        queryFn: getChangeLogs,
        refetchOnWindowFocus: false,
        retry: retryFn
    });

    // -------------------------- MUTATIONS --------------------------
    const addChangeLogMutation = useMutation({
        mutationKey: ['addChangeLogs'],
        mutationFn: addChangeLogs,
        gcTime: 0,
        onSuccess: (response) => {
            if (!err_message) {
                successAlert.current?.show("ChangeLog have been added successfully!");
                err_message = "";
            } else {
                errorAlert.current?.show(err_message);
                err_message = "";
            }
            setChangeLogs(response.data);
            setAddChangeLogToModal(false)
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to save ChangeLogs: ${error}`);
        },
    });

    const removeChangeLogsMutation = useMutation({
        mutationKey: ['removeChangeLogs'],
        mutationFn: removeChangeLogs,
        gcTime: 0,
        onSuccess: (response) => {
            successAlert.current?.show("ChangeLogs have been removed successfully!");
            setChangeLogs(response.data);
            setRemoveActionConfirmationModal(false);
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to remove ChangeLogs: ${error}`);
        },
    })

    const editChangeLogMutation = useMutation({
        mutationKey: ['editChangeLogs'],
        mutationFn: editChangeLogs,
        gcTime: 0,
        onSuccess: (response) => {
            successAlert.current?.show("ChangeLog edited successfully!");
            setChangeLogs(response.data);
            setEditChangeLogModal(false);
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to edit ChangeLog: ${error}`);
        },
    })

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            setChangeLogs(data['data']);
        }
    }, [data]);

    // --------------------- REFS ---------------------
    const errorAlert = useRef<any>(null);
    const successAlert = useRef<any>(null);

    // ---------------------- FUNCTIONS ----------------------
    function selectedRowsSetter(rowModel: RowModel<RowData>) {
        setSelectedRows(rowModel);
    }

    function onRemoveChangeLogs(changeLogIds: Array<string>) {
        if (changeLogIds.length < 1) {
            errorAlert.current?.show("Please select at least one changelog to remove.");
            return;
        }
        // on remove multiple selected changelog from the list
        removeChangeLogsMutation.mutate({
            changeLogIds: changeLogIds,
        });
    }

    function onEditChangeLog(changeLogId: string, title: string, description: string, created_at: string) {
        if (!changeLogId) {
            errorAlert.current?.show("Please select at least one changelog to remove.");
            return;
        }
        // on enable ChangeLog
        editChangeLogMutation.mutate({
            changeLogId: changeLogId,
            title: title,
            description: description,
            date: created_at
        });
    }

    const handleEditClick = (changeLog: ChangeLog) => {
        setChangeLogID(changeLog.id);
        setChangeLogTitle(changeLog.title);
        setChangeLogDate(changeLog.created_at);
        setChangeLogDescription(changeLog.description);
        setEditChangeLogModal(true);
    };

    const handleEditSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (changeLogID) {
            onEditChangeLog(changeLogID, changeLogTitle, changeLogDescription, changeLogDate);
        }
    };


    const formatDateForInput = (date: string) => {
        const newDate = new Date(date.replace(" ", "T")); 
        if (!isNaN(newDate.getTime())) {
            return newDate.toISOString().slice(0, 16);
        }
        return "";
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!changeLogTitle || !changeLogDescription) {
            errorAlert.current?.show("ChangeLog Title and Description required.");
            return;
        }
        addChangeLogMutation.mutate({
            title: changeLogTitle,
            description: changeLogDescription,
            date: changeLogDate
        });
    }
    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<ChangeLog>();
    const columnDefs: ColumnDef<any, any>[] = [
        columnHelper.accessor((row: ChangeLog) => row.id, {
            id: 'checkbox',
            header: ({ table }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler(),
                    }}
                />
            ),
            cell: ({ row }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                    name={"changeLogSelection"}
                    value={row.original.id}
                />
            ),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: ChangeLog) => row.title, {
            id: 'title',
            header: "Title",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
        }),
        
        columnHelper.accessor((row) => row.created_at, {
            id: "created_on",
            header: "Created On",
            cell: (info) => {
                return DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATETIME_MED);
            },
            enableGlobalFilter: false,
        }),

        columnHelper.display({
            id: 'editChangeLog',
            header: () => (<div style={{ textAlign: "center" }}>Edit</div>),
            cell: props => {
                return (
                    <button className={"button is-primary is-small is-outlined"} onClick={() => handleEditClick(props.row.original)} >
                        Edit&nbsp;&nbsp;
                    </button>
                );
            },
            enableGlobalFilter: false,
            meta: {
                align: 'center'
            }
        }),
    ]
    
    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isFetching) {
        return (
            <p style={{ textAlign: "center", fontSize: "1.3rem" }} className="mt-5">
                Loading Data...<FontAwesomeIcon icon={"spinner"} className={"ml-5"} />
            </p>
        )
    } else if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">ChangeLogs</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        {/* ******************* Add ChangeLog Modal ******************* */}
                        <Modal active={addChangeLogToModal}
                            headerText={"Add ChangeLog"}
                            closeable={true}
                            hideModal={() => {
                                setAddChangeLogToModal(false);
                            }
                            }>
                            <div className="tab-content">
                                <form onSubmit={handleSubmit}>
                                    <label className="label">Title</label>
                                    <input type="text" className="input form-control" onChange={(e)=>setChangeLogTitle(e.target.value)} placeholder="Enter ChangeLog Title" />
                                    <label className="label mt-2">Date</label>
                                    <input type="datetime-local" className="input form-control mt" value={changeLogDate} onChange={(e) => setChangeLogDate(e.target.value)}/>
                                    <label className="label mt-2">Description</label>
                                    <TextArea
                                        className={"textarea"}
                                        placeholder={"Enter ChangeLog Description"}
                                        value={changeLogDescription}
                                        onChange={(e)=>setChangeLogDescription(e.target.value)}
                                    />
                                    <button type="submit" className={"button is-primary is-fullwidth mt-4"}>Add ChangeLog</button>
                                </form>
                            </div>
                        </Modal>
                        
                        {/* ******************* Edit ChangeLog Modal ******************* */}
                        <Modal active={editChangeLogModal}
                                headerText={"Edit ChangeLog"}
                                closeable={true}
                                hideModal={() => setEditChangeLogModal(false)} >
                            <div className="tab-content">
                                <form onSubmit={handleEditSubmit}>
                                    <label className="label">Title</label>
                                    <input
                                        type="text"
                                        className="input form-control"
                                        value={changeLogTitle}
                                        onChange={(e) => setChangeLogTitle(e.target.value)}
                                        placeholder="Enter ChangeLog Title"
                                    />
                                    <label className="label mt-2">Date</label>
                                    <input
                                        type="datetime-local"
                                        className="input form-control mt"
                                        value={formatDateForInput(changeLogDate)}
                                        onChange={(e) => setChangeLogDate(e.target.value)}
                                    />
                                    <label className="label mt-2">Description</label>
                                    <TextArea
                                        className={"textarea"}
                                        placeholder={"Enter ChangeLog Description"}
                                        value={changeLogDescription}
                                        onChange={(e) => setChangeLogDescription(e.target.value)}
                                    />
                                    <button type="submit" className={"button is-primary is-fullwidth mt-4"}>
                                        Save Changes
                                    </button>
                                </form>
                            </div>
                        </Modal>
                        
                        {/* ******************* Remove confirmation Modal ******************* */}
                        <Modal active={RemoveActionConfirmationModal}
                            headerText={""}
                            closeable={true}
                            hideModal={() => setRemoveActionConfirmationModal(false)}>
                            <h1 className="title has-text-centered">Are you sure you want to remove the selected ChangeLog(s)?</h1>
                            <div className="buttons is-centered">
                                <button className={"button is-danger is-outlined"}
                                    onClick={() => {
                                        setRemoveActionConfirmationModal(false);
                                        if (selectedRows && selectedRows.rows.length > 0) {
                                            const selectedNames = selectedRows.rows.map(row => {
                                                return (row.original as ChangeLog).id
                                            });
                                            onRemoveChangeLogs(selectedNames);
                                            selectedRows.rows.forEach(row => {
                                                row.toggleSelected();
                                            });
                                        }
                                    }}>
                                    Remove Selected
                                </button>
                                <button className={"button is-primary is-outlined"}
                                    onClick={() => setRemoveActionConfirmationModal(false)}>
                                    Cancel
                                </button>
                            </div>
                        </Modal>

                        <AbunTable tableContentName={"changeLogs"}
                            tableData={changeLogs}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[0]}
                            noDataText={
                                changeLogs.length === 0 ?
                                    "No changeLogs available."
                                    : "No changeLogs found."
                            }
                            searchboxPlaceholderText={"Search changeLogs..."}
                            rowCheckbox={true}
                            selectedRowsSetter={selectedRowsSetter}
                            buttons={[
                                {
                                    text: "Add ChangeLog",
                                    type: "primary",
                                    clickHandler: () => setAddChangeLogToModal(true)
                                },
                                {
                                    text: "Remove All Selected ChangeLog",
                                    type: "danger",
                                    clickHandler: () => setRemoveActionConfirmationModal(true),
                                    invisible: !selectedRows || selectedRows.rows.length < 1
                                },
                                {
                                    text: "Refresh",
                                    type: "primary",
                                    clickHandler: () => refetch()
                                }
                            ]} />
                    </div>
                </div>
                <Alert type={"danger"} ref={errorAlert} />
                <Alert type={"success"} ref={successAlert} />
            </section>
        );
    }
}
