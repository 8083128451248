import webflowLogo from "../../assets/images/webflow.png";
import wixLogo from "../../assets/images/wix.png";
import wordpressLogo from "../../assets/images/wordpress.png";
import shopifyLogo from "../../assets/images/Shopify-logo-success.png";
import ghostLogo from "../../assets/images/ghost.png";
import "./integration.scss"
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUserIntegrations, removeIntegrationMutation, retryFn } from "utils/api";
import AbunButton from "../../components/AbunButton/AbunButton";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunTable from "../../components/AbunTable/AbunTable";
import Icon from "../../components/Icon/Icon";

import { NavLink } from "react-router-dom";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";


interface IntegrationProps {
	currentPlanName: string
	websiteConnectionLimit: number
	userID: string
}

export default function Integration(props: IntegrationProps) {

	// For webflow integration
	const [totalWebflowSitesConnected, setTotalWebflowSitesConnected] = useState(0);
	const [webflowSites, setWebflowSites] = useState([]);


	// For wix integration
	const [totalWixSitesConnected, setTotalWixSitesConnected] = useState(0);
	const [wixSites, setWixSites] = useState([]);


	// For shopify integration
	const [totalShopifyShopConnected, setTotalShopifyShopConnected] = useState(0);
	const [shopifyShops, setShopifyShops] = useState([]);


	// For wordpress integration
	const [totalWordpressSitesConnected, setTotalWordpressSitesConnected] = useState(0);
	const [wordpressSites, setWordpressSites] = useState([]);

	// For ghost integration
	const [totalGhostSitesConnected, setTotalGhostSitesConnected] = useState(0);
	const [ghostSites, setGhostSites] = useState([]);

	const [websiteConnectionLimit, __] = useState(props.websiteConnectionLimit);

	const [tableData, setTableData] = useState([]);
	const [tableColumnDefs, setTableColumnDefs] = useState<Array<ColumnDef<any, any>>>([]);

	const [
		integrationViewSitesModal,
		setIntegrationViewSitesModal
	] = useState({ active: false, integration: "", modelWidth: "" });

	const [
		integrationUniqueID,
		setIntegrationUniqueID
	] = useState("");

	const [
		integrationDeleteModal,
		setIntegrationDeleteModal
	] = useState({ active: false, integration: "" });

	// --------------------- MUTATIONS ---------------------
	
	const removeIntegrationMut = useMutation({
		mutationKey: ['removeIntegrationMutation'],
		mutationFn: removeIntegrationMutation,
		gcTime: 0,
	})


	// --------------------- QUERY ---------------------
	const {
		isLoading,
		isError,
		error,
		data,
		refetch
	} = useQuery({
		queryKey: ['getUserIntegrations', props.userID],
		queryFn: () => getUserIntegrations(props.userID),
		refetchOnWindowFocus: false,
		retry: retryFn
	})

	// --------------------- EFFECTS ---------------------
	useEffect(() => {
		if (data) {
			let integrations = data['data']['integrations']

			setTotalWordpressSitesConnected(integrations['wordpress']['count']);
			setTotalWebflowSitesConnected(integrations['webflow']['count']);
			setTotalWixSitesConnected(integrations['wix']['count']);
			setTotalShopifyShopConnected(integrations['shopify']['count']);
			setTotalGhostSitesConnected(integrations['ghost']['count']);

			setWordpressSites(integrations['wordpress']['sites'])
			setWebflowSites(integrations['webflow']['sites'])
			setWixSites(integrations['wix']['sites'])
			setShopifyShops(integrations['shopify']['shops'])
			setGhostSites(integrations['ghost']['sites'])
		}
	}, [data]);

	/**
	 * Removes all integration from user website
	 */
	function removeAllIntegrations(integration_type: string) {
		
		removeIntegrationMut.mutate({
			user_id: props.userID,
			integration_type: integration_type,
			integration_unique_id: integrationUniqueID
		},
		{
			onSuccess: () => {
				if(integration_type === 'wordpress'){
					const updatedSites = wordpressSites.filter(item => item['site_url'] !== integrationUniqueID);
					setWordpressSites(updatedSites);
					setTotalWordpressSitesConnected(totalWordpressSitesConnected-1)
				}
				else if(integration_type === 'webflow'){
					const updatedSites = webflowSites.filter(item => item['site_id'] !== integrationUniqueID);
					setWebflowSites(updatedSites);
					setTotalWebflowSitesConnected(totalWebflowSitesConnected-1)
				}
				else if(integration_type === 'wix'){
					const updatedSites = wixSites.filter(item => item['site_id'] !== integrationUniqueID);
					setWixSites(updatedSites);
					setTotalWixSitesConnected(totalWixSitesConnected-1)
				}
				else if(integration_type === 'shopify'){
					const updatedShops = shopifyShops.filter(item => item['shop_url'] !== integrationUniqueID);
					setShopifyShops(updatedShops);
					setTotalShopifyShopConnected(totalShopifyShopConnected-1)
				}
				else if(integration_type === 'ghost'){
					const updatedSites = ghostSites.filter(item => item['site_url'] !== integrationUniqueID);
					setGhostSites(updatedSites);
					setTotalGhostSitesConnected(totalGhostSitesConnected-1);
				}
				setIntegrationDeleteModal({ active: false, integration: "" });
			},

			onError: () =>{
				setIntegrationDeleteModal({ active: false, integration: "" });
			}
		})
	}

	function setUpTableForWebflow() {
		interface WebflowTable {
			site_id: string,
			collection_id: string,
			site_url: string,
		}

		// -------------------------- TABLE SETUP --------------------------

		const columnHelper = createColumnHelper<WebflowTable>();
		const columnDefs: ColumnDef<any, any>[] = [
			columnHelper.accessor((row: WebflowTable) => row.site_url, {
				id: 'siteURL',
				header: () => (<div style={{ textAlign: "center" }}>Site URL</div>),
				cell: props => {
					const siteURL = props.row.original.site_url;
					if (siteURL.length <= 20) {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url}
								</NavLink>
							</div>
						)
					} else {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url.substring(0, 17) + "..."}
								</NavLink>
							</div>
						)
					}
				},
				enableGlobalFilter: true,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.accessor((row: WebflowTable) => row.site_id, {
				id: 'siteID',
				header: "Site ID",
				cell: info => info.getValue().toString(),
				enableGlobalFilter: true,
			}),
			columnHelper.accessor((row: WebflowTable) => row.collection_id, {
				id: 'collectionID',
				header: () => (<div style={{ textAlign: "center" }}>Collection ID</div>),
				cell: info => info.getValue().toString(),
				enableGlobalFilter: true,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.display({
				id: "remove",
				header: () => "Remove",
				cell: props => {
					return (
						<AbunButton className={"integration-button"} type={"danger"} clickHandler={() => {
							setIntegrationUniqueID(props.row.original.site_id);
							setIntegrationViewSitesModal({ active: false, integration: "", modelWidth: "" });
							setIntegrationDeleteModal({ active: true, integration: "webflow" });
						}}>
							<Icon iconName={"trash"} additionalClasses={["icon-white"]} />&nbsp;&nbsp;Remove
						</AbunButton>
					)
				}
			})

		]
		setTableColumnDefs(columnDefs);
		setTableData(webflowSites)

	}

	function setUpTableForWix() {
		interface WixTable {
			site_id: string,
			site_url: string,
		}

		// -------------------------- TABLE SETUP --------------------------

		const columnHelper = createColumnHelper<WixTable>();
		const columnDefs: ColumnDef<any, any>[] = [
			columnHelper.accessor((row: WixTable) => row.site_url, {
				id: 'siteURL',
				header: () => (<div style={{ textAlign: "center" }}>Site URL</div>),
				cell: props => {
					const siteURL = props.row.original.site_url;
					if (!siteURL) {
						return (
							<div>––</div>
						)
					} else if (siteURL.length <= 20) {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url}
								</NavLink>
							</div>
						)
					} else {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url.substring(0, 17) + "..."}
								</NavLink>
							</div>
						)
					}
				},
				enableGlobalFilter: true,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.accessor((row: WixTable) => row.site_id, {
				id: 'siteID',
				header: "Site ID",
				cell: info => info.getValue().toString(),
				enableGlobalFilter: true,
			}),
			columnHelper.display({
				id: "remove",
				header: () => "Remove",
				cell: props => {
					return (
						<AbunButton className={"integration-button"} type={"danger"} clickHandler={() => {
							setIntegrationUniqueID(props.row.original.site_id);
							setIntegrationViewSitesModal({ active: false, integration: "", modelWidth: "" });
							setIntegrationDeleteModal({ active: true, integration: "wix" });
						}}>
							<Icon iconName={"trash"} additionalClasses={["icon-white"]} />&nbsp;&nbsp;Remove
						</AbunButton>
					)
				}
			})
		]
		setTableColumnDefs(columnDefs);
		setTableData(wixSites)

	}

	function setUpTableForWordpress() {
		interface Wordpress {
			site_url: string,
		}

		// -------------------------- TABLE SETUP --------------------------

		const columnHelper = createColumnHelper<Wordpress>();
		const columnDefs: ColumnDef<any, any>[] = [
			columnHelper.accessor((row: Wordpress) => row.site_url, {
				id: 'siteURL',
				header: () => (<div style={{ textAlign: "center" }}>Site URL</div>),
				cell: props => {
					const siteURL = props.row.original.site_url;
					if (siteURL.length <= 20) {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url}
								</NavLink>
							</div>
						)
					} else {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url}
								</NavLink>
							</div>
						)
					}
				},
				enableGlobalFilter: true,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.display({
				id: "remove",
				header: () => "Remove",
				cell: props => {
					return (
						<AbunButton className={"integration-button"} type={"danger"} clickHandler={() => {
							setIntegrationUniqueID(props.row.original.site_url);
							setIntegrationViewSitesModal({ active: false, integration: "", modelWidth: "" });
							setIntegrationDeleteModal({ active: true, integration: "wordpress" });
						}}>
							<Icon iconName={"trash"} additionalClasses={["icon-white"]} />&nbsp;&nbsp;Remove
						</AbunButton>
					)
				}
			})

		]
		setTableColumnDefs(columnDefs);
		setTableData(wordpressSites)
	}

	function setUpTableForShopify() {
		interface ShopifyTable {
			shop_url: string,
		}

		// -------------------------- TABLE SETUP --------------------------
		const columnHelper = createColumnHelper<ShopifyTable>();
		const columnDefs: ColumnDef<any, any>[] = [
			columnHelper.accessor((row: ShopifyTable) => row.shop_url, {
				id: 'shopURL',
				header: () => (<div style={{ textAlign: "center" }}>Shop URL</div>),
				cell: props => {
					const shopURL = props.row.original.shop_url;
					if (!shopURL) {
						return (
							<div>––</div>
						)
					} else if (shopURL.length <= 20) {
						return (
							<div>
								<NavLink to={"https://" + props.row.original.shop_url} target="_blank">
									{props.row.original.shop_url}
								</NavLink>
							</div>
						)
					} else {
						return (
							<div>
								<NavLink to={"https://" + props.row.original.shop_url} target="_blank">
									{props.row.original.shop_url.substring(0, 17) + "..."}
								</NavLink>
							</div>
						)
					}
				},
				enableGlobalFilter: true,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.display({
				id: "remove",
				header: () => "Remove",
				cell: props => {
					return (
						<AbunButton className={"integration-button"} type={"danger"} clickHandler={() => {
							setIntegrationUniqueID(props.row.original.shop_url);
							setIntegrationViewSitesModal({ active: false, integration: "", modelWidth: "" });
							setIntegrationDeleteModal({ active: true, integration: "shopify" });
						}}>
							<Icon iconName={"trash"} additionalClasses={["icon-white"]} />&nbsp;&nbsp;Remove
						</AbunButton>
					)
				}
			})
		]
		setTableColumnDefs(columnDefs);
		setTableData(shopifyShops)
	}

	function setUpTableForGhost() {
		interface GhostTable {
			site_url: string,
		}

		// -------------------------- TABLE SETUP --------------------------

		const columnHelper = createColumnHelper<GhostTable>();
		const columnDefs: ColumnDef<any, any>[] = [
			columnHelper.accessor((row: GhostTable) => row.site_url, {
				id: 'siteURL',
				header: () => (<div style={{ textAlign: "center" }}>Site URL</div>),
				cell: props => {
					const siteURL = props.row.original.site_url;
					if (!siteURL) {
						return (
							<div>––</div>
						)
					} else if (siteURL.length <= 20) {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url}
								</NavLink>
							</div>
						)
					} else {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url.substring(0, 17) + "..."}
								</NavLink>
							</div>
						)
					}
				},
				enableGlobalFilter: true,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.display({
				id: "remove",
				header: () => "Remove",
				cell: props => {
					return (
						<AbunButton className={"integration-button"} type={"danger"} clickHandler={() => {
							setIntegrationUniqueID(props.row.original.site_url);
							setIntegrationViewSitesModal({ active: false, integration: "", modelWidth: "" });
							setIntegrationDeleteModal({ active: true, integration: "ghost" });
						}}>
							<Icon iconName={"trash"} additionalClasses={["icon-white"]} />&nbsp;&nbsp;Remove
						</AbunButton>
					)
				}
			})
		]
		setTableColumnDefs(columnDefs);
		setTableData(ghostSites)
	}


	return <>
		{/* ---------------------------- AVAILABLE INTEGRATIONS ---------------------------- */}
		<div className={"card w-100 mt-4"}>
			<div className={"card-header"}>
				<p className={"card-header-title"}>Integrations</p>
			</div>

			{/* ==================================================================================================== */}
			{/* ------------------------------------- Integration Delete Alert ------------------------------------- */}
			{/* ==================================================================================================== */}
			<AbunModal active={integrationDeleteModal.active}
				headerText={"Abun Integrations"}
				closeable={false}
				hideModal={() => {
				}}>
				<div>
					<h4 className={"is-size-4 has-text-centered"}>Confirm Deleting Integration?</h4>
					<p className={"has-text-centered mt-4"}>
						This will immediately remove the integration from your abun account and stop your Content Automation & Schedule Article feature.
						Any pending Content Automation & Scheduled Article will not be published.
					</p>
					<div className={"mt-6 has-text-centered is-flex is-justify-content-center is-align-items-center"}>
						<AbunButton type={"danger"} clickHandler={() => {
							removeAllIntegrations(integrationDeleteModal.integration);
						}}>
							Yes, Delete
						</AbunButton>
						<AbunButton type={"primary"} className={"ml-4"} clickHandler={() => {
							setIntegrationDeleteModal({ active: false, integration: "" });
						}}>
							Cancel
						</AbunButton>
					</div>
				</div>
			</AbunModal>

			{/* ==================================================================================================== */}
			{/* ---------------------------------- Integration View Sites Alert ------------------------------------ */}
			{/* ==================================================================================================== */}
			<AbunModal active={integrationViewSitesModal.active}
				headerText={`${integrationViewSitesModal.integration} Sites`}
				closeable={true}
				modelWidth={integrationViewSitesModal.modelWidth}
				hideModal={() => {
					setIntegrationViewSitesModal({ active: false, integration: "", modelWidth: "" });
				}}>
				{
					<AbunTable
						tableContentName={`Connected ${integrationViewSitesModal.integration} Sites`}
						tableData={tableData}
						columnDefs={tableColumnDefs}
						pageSizes={[5, 10, 15]}
						initialPageSize={5}
						searchDisabled={true}
						searchboxPlaceholderText={""}
						noDataText={`No ${integrationViewSitesModal.integration} site is connected.`} />
				}
			</AbunModal>

			<div className={"card-content"}>
				<div className={"content"}>
					<ul className={"integrations-list"}>
						{/* ************************ WORDPRESS ************************ */}
						<li className={"integration-list-item"}>
							<div className={"is-flex is-flex-direction-row"}>
								<img src={wordpressLogo} className={"integration-item-logo"} alt="wordpress logo" />
								{totalWordpressSitesConnected != 0 ?
									<p className={"integration-item-name integration-item-installed"}>
										Wordpress ({totalWordpressSitesConnected}/{websiteConnectionLimit})
									</p> :
									<p className={`integration-item-name integration-item-not-installed`}>
										Wordpress ({totalWordpressSitesConnected}/{websiteConnectionLimit})
									</p>
								}
							</div>

							<AbunButton className={"integration-button"} type={"primary"} disabled={totalWordpressSitesConnected == 0 ? true : false} clickHandler={() => {
								setUpTableForWordpress();
								setIntegrationViewSitesModal({ active: true, integration: "Wordpress", modelWidth: "750px" });
							}}>
								View Sites
							</AbunButton>

						</li>
						{/* ************************ WEBFLOW ************************ */}
						<li className={"integration-list-item"}>
							<div className={"is-flex is-flex-direction-row"}>
								<img src={webflowLogo} className={"integration-item-logo"} alt="Webflow logo" />

								{totalWebflowSitesConnected != 0 ?
									<p className={"integration-item-name integration-item-installed"}>
										Webflow ({totalWebflowSitesConnected}/{websiteConnectionLimit})
									</p> :
									<p className={`integration-item-name integration-item-not-installed`}>
										Webflow ({totalWebflowSitesConnected}/{websiteConnectionLimit})
									</p>
								}
							</div>
							<AbunButton className={"integration-button"} type={"primary"} disabled={totalWebflowSitesConnected == 0 ? true : false} clickHandler={() => {
								setUpTableForWebflow();
								setIntegrationViewSitesModal({ active: true, integration: "Webflow", modelWidth: "750px" });
							}}>
								View Sites
							</AbunButton>
						</li>
						{/* ************************ WIX ************************ */}
						<li className={"integration-list-item"}>
							<div className={"is-flex is-flex-direction-row"}>
								<img src={wixLogo} className={"integration-item-logo"} alt="WIX logo" />

								{totalWixSitesConnected != 0 ?
									<p className={"integration-item-name integration-item-installed"}>
										WIX ({totalWixSitesConnected}/{websiteConnectionLimit})
									</p> :
									<p className={`integration-item-name integration-item-not-installed`}>
										WIX ({totalWixSitesConnected}/{websiteConnectionLimit})
									</p>
								}
							</div>

							<AbunButton className={"integration-button"} type={"primary"} disabled={totalWixSitesConnected == 0 ? true : false} clickHandler={() => {
								setUpTableForWix();
								setIntegrationViewSitesModal({ active: true, integration: "Wix", modelWidth: "600px" });
							}}>
								View Sites
							</AbunButton>
						</li>
						{/* ************************ SHOPIFY ************************ */}
						<li className={"integration-list-item"}>
							<div className={"is-flex is-flex-direction-row"}>
								<img src={shopifyLogo} className={"integration-item-logo"} alt="SHOPIFY logo" />

								{totalShopifyShopConnected != 0 ?
									<p className={"integration-item-name integration-item-installed"}>
										Shopify ({totalShopifyShopConnected}/{websiteConnectionLimit})
									</p> :
									<p className={`integration-item-name integration-item-not-installed`}>
										Shopify ({totalShopifyShopConnected}/{websiteConnectionLimit})
									</p>
								}
							</div>

							<AbunButton className={"integration-button"} type={"primary"} disabled={totalShopifyShopConnected == 0 ? true : false} clickHandler={() => {
								setUpTableForShopify();
								setIntegrationViewSitesModal({ active: true, integration: "Shopify", modelWidth: "600px" });
							}}>
								View Sites
							</AbunButton>
						</li>
						{/* ************************ GHOST ************************ */}
						<li className={"integration-list-item"}>
							<div className={"is-flex is-flex-direction-row"}>
								<img src={ghostLogo} className={"integration-item-logo"} alt="ghost logo" />
								{totalGhostSitesConnected != 0 ?
									<p className={"integration-item-name integration-item-installed"}>
										Ghost ({totalGhostSitesConnected}/{websiteConnectionLimit})
									</p> :
									<p className={`integration-item-name integration-item-not-installed`}>
										Ghost ({totalGhostSitesConnected}/{websiteConnectionLimit})
									</p>
								}
							</div>

							<AbunButton className={"integration-button"} type={"primary"} disabled={totalGhostSitesConnected == 0 ? true : false} clickHandler={() => {
								setUpTableForGhost();
								setIntegrationViewSitesModal({ active: true, integration: "Ghost", modelWidth: "750px" });
							}}>
								View Sites
							</AbunButton>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</>
}